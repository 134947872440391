<template>
    <div>
        <header class="main_header_arae">
            <!-- Top Bar -->
            <div class="topbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                        </div>
                        <div class="col-lg-6 col-md-6" >
                            <ul class="topbar-others-options" >
                                <li  v-if="!isLogged" ><router-link to="/login">Login</router-link></li>
                                <li   style="color:white;cursor:pointer"  v-if="isLogged" ><router-link to="/hotel-booking">Histórico</router-link></li>
                                <li   style="color:white;cursor:pointer"  v-if="isLogged" ><a @click="logOut">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Navbar Bar -->
            <div class="navbar-area" :class="{ 'is-sticky': isSticky }">
                <div class="main-responsive-nav">
                    <div class="container">
                        <div class="main-responsive-menu mean-container">
                            <nav class="navbar">
                                <div class="container">
                                    <div class="logo">
                                        <router-link to="/">
                                            <img src="../assets/img/logo.png" alt="logo" style="height:50px">
                                        </router-link>
                                    </div>
                                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbar-content">
                                        <div class="hamburger-toggle">
                                            <div class="hamburger">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </button>
                                    <div class="collapse navbar-collapse mean-nav" id="navbar-content">
                                        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                                          
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="outside">Flights</router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/flight-search-result"
                                                            class="dropdown-item">Flight</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/flight-booking-submission"
                                                            class="dropdown-item">Flight
                                                            Booking</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <router-link to="/" class="dropdown-item dropdown-toggle"
                                                    data-bs-toggle="dropdown" data-bs-auto-close="outside">Hotel
                                                </router-link>
                                                <ul class="dropdown-menu shadow">
                                                    <li class="nav-item">
                                                        <router-link to="/hotel-search"
                                                            class="dropdown-item">Hotel</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/hotel-details" class="dropdown-item">Hotel
                                                            Booking</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/room-details" class="dropdown-item">Room
                                                            Details</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/room-booking" class="dropdown-item">Room
                                                            Booking</router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/contact" class="dropdown-item">Contact</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="main-navbar">
                    <div class="container">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <router-link class="navbar-brand" to="/">
                                <img src="../assets/img/logo.png" alt="logo" style="height:75px">
                            </router-link>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="container">
                            <div class="option-inner">
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                                    </div>
                                    <div class="option-item">
                                        <router-link to="/contact" class="btn btn_navber">Get free quote</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
 import { useEventBus } from '@vueuse/core'
import { mapInjectionKey } from "@/useCookie";


export default {
    name: 'Header',
    data() {
        return {
            language: 'English',
            currency: 'USD',
            isSticky: false,
            isLogged:false
        }
    },
    methods: {
        logOut() {
            this.isLogged = false;
            Cookies.remove('token');
            this.$router.push('/login');
        }
    },
    mounted() {

        this.isLogged = Cookies.get('token') || null;   

        const bus = useEventBus(mapInjectionKey);
        bus.on((e) => {
            this.isLogged = (e != null);
        });


        window.addEventListener('scroll', () => {
            let scroll = window.scrollY
            if (scroll >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })

        document.addEventListener('click', function (e) {
            // Hamburger menu
            if (e.target.classList.contains('hamburger-toggle')) {
                e.target.children[0].classList.toggle('active');
            }
        })
    }
}
</script>

