<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="tou_booking_form_Wrapper">
                        <div class="tour_booking_form_box mb-4">
                            <div class="booking_success_arae">
                                <div class="booking_success_img">
                                    <img src="../../assets/img/icon/right.png" alt="img">
                                </div>
                                <div class="booking_success_text">
                                    <h3>A sua reserva foi concluída com sucesso!</h3>
                                    <!--
                                    <h6>Os detalhe da reserva foram enviados para o seu email: yourmail@domain.com</h6>
                                    -->
                                </div>
                            </div>
                        </div>
                        <div class="booking_tour_form">
                            <h3 class="heading_theme">A sua informação</h3>
                            <div class="tour_booking_form_box">
                                <div class="your_info_arae">
                                    <ul>
                                        <li><span class="name_first">Primeiro nome:</span> <span
                                                class="last_name">{{ firstName }}</span></li>
                                        <li><span class="name_first">Último nome:</span> <span
                                                class="last_name">{{ lastName }}</span></li>
                                        <li><span class="name_first">Email:</span> <span
                                                class="last_name">{{ email }}</span></li>
                                                <li><span class="name_first">Telemóvel:</span> <span
                                                class="last_name">{{ phone }}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" v-if="!loading">
                    <div class="tour_details_right_sidebar_wrapper">
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Detalhe da Reserva</h3>
                                </div>
                                <div class="tour_booking_amount_area">
                                    <ul>
                                        <li>{{ hotelName }}</li>
                                        <li>ID Reserva: <span  class="underline">{{ supplierBookReference }}</span></li>
                                        <li>Date Reserva: <span>{{date.split('T')[0]  }}</span></li>
                                    </ul>
                                    <ul>
                                        <li>Checkin: <span>{{ this.fromDate }}</span></li>
                                        <li>Checkout: <span>{{ this.toDate }}</span></li>
                                        <li>{{ this.nights }} Noite(s) <span>{{ total }}€</span></li>
                                    </ul>
                                
                                
                                    <div class="total_subtotal_booking">
                                        <h6 class="remove_coupon_tour">Total <span>{{ total }}€</span> </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row">
                <div class="col-lg-12">
                    <div class="tou_booking_form_Wrapper">
                        <div class="booking_tour_form">
                            <h3 class="heading_theme">Dados SIGAV</h3>
                            <div class="tour_booking_form_box">
                                <form action="!#" id="tour_bookking_form_item">
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label>Balcão</label>
                                                <select id="departmentsDropdown" class="form-control form-select bg_input" :class="departmentClass"  v-model="selectedDepartment">
                                                   <option v-for="department in departments" :key="department.id" :value="department.id">{{ department.number }} - {{ department.name }}</option>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="form-group">
                                                <label>Ficheiro</label>
                                                <input type="text" maxlength="7" class="form-control bg_input" :class="fileClass" v-model="sigavFile"
                                                    placeholder="">
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="form-group">
                                                <label>Ano</label>
                                                <select id="yearDropdown"  class="form-control form-select bg_input"  v-model="year">
                                                    <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
                                                 </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Fornecedor Sigav</label>
                                                <select id="supplierDropdown" class="form-control form-select bg_input" :class="supplierClass" v-model="selectedSupplier" >
                                                   <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{ supplier.name }}</option>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Cliente Sigav</label>
                                                <select id="clientDropdown" class="form-control form-select bg_input" :class="clientClass" v-model="selectedClient" >
                                                   <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label>Aeroporto Mais Próximo</label>
                                                <input type="text" maxlength="3" class="form-control bg_input" v-model="airport" :class="airportClass"
                                                    placeholder="Cód. Aeroporto">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label>Valor de Venda</label>
                                                <input type="number" class="form-control bg_input" v-model="saleValue" :class="saleClass"
                                                    placeholder="Valor de Venda">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label>Taxa de Emissão</label>
                                                <input type="number" class="form-control bg_input" v-model="fee" :class="feeClass"
                                                    placeholder="Taxa de Emissão">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Motivo Tarifa</label>
                                                <select id="motivesDropdown" class="form-control form-select bg_input"  v-model="selectedMotive" :class="drpMotiveClass">
                                                   <option v-for="motive in motives" :key="motive.id" :value="motive.id">{{ motive.code }} - {{ motive.description }}</option>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Motivo Fee</label>
                                                <select id="feesDropdown" class="form-control form-select bg_input" v-model="selectedFee" :class="drpFeeClass">
                                                   <option v-for="fee in fees" :key="fee.id" :value="fee.id">{{ fee.description }}</option>
                                                  </select>
                                            </div>
                                        </div>
                                       
                               
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="remarks"
                                                    placeholder="Observações">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="costCenter"
                                                    placeholder="Centro de Custo">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="department"
                                                    placeholder="Departamento">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="projectNumber"
                                                    placeholder="Nº de Projecto">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="requestedBy"
                                                    placeholder="Pedido Por">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="requisition"
                                                    placeholder="Requisição">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="accountingUnit"
                                                    placeholder="Unidade Contabilística">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="accountingNumber"
                                                    placeholder="Número Contabilístico">
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="employeeNumber"
                                                    placeholder="Número de Empregado">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="booking_tour_form_submit">
                            <a @click="saveSIGAV()" class="btn btn_theme btn_md">Integrar no SIGAV</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section>

</template>
<script>
import api from '@/api.js';
import router from '@/router';

export default {
    name: "TourBooking",
    data() {
        return {
            hotel: null,
            room: null,
            rate: null,
            from: null,
            to: null,
            adults: null,
            children: null,
            rooms: null,
            bookingId: null,
            rateId: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            total:'',
            loading:true,
            supplierBookReference:'',
            hotelName:'',
            costCenter: '',
            department: '',
            projectNumber: '',
            requestedBy: '',
            employeeNumber:'',
            accountingNumber:'',
            accountingUnit:'',
            requisition:'',
            remarks:'',
            selectedMotive:'7fbf82e3-f40e-4c1a-ba60-79b5cc1137da',
            selectedFee:'6eb9332c-2598-4893-9a3f-5497f95bb55c',
            selectedSupplier:'',
            sigavFile:'',
            year:'',
            airport:'',
            saleValue:'',
            fee:'0.0',
            motives:[],
            fees:[],
            departments:[],
            availableYears:[],
            suppliers:[],
            clients:[],
            selectedClient:null,
            selectedDepartment:null
        }
    },
    methods:{
       async saveSIGAV(){
       
            //Validate mandatory fields
            if(this.selectedDepartment === null ||
                this.sigavFile == null || this.sigavFile.length !== 7 ||
                this.selectedSupplier === null || this.selectedSupplier === '' ||
                this.saleValue === null || this.saleValue === '' ||
                this.fee === null || this.fee === '' ||
                this.airport === null || this.airport.length !== 3 ||
                this.selectedFee === null || this.selectedFee === '' ||
                this.selectedMotive === null || this.selectedMotive === '' ||
                this.selectedClient === null || this.selectedClient === ''){
                    return;            
            }

            await api.post(`/sigav/save`, {
                    BookingId: this.bookingId,
                    CostCenter: this.costCenter,
                    Department: this.department,
                    ProjectNumber: this.projectNumber,
                    RequestedBy: this.requestedBy,
                    EmployeeNumber:this.employeeNumber,
                    AccountingNumber:this.accountingNumber,
                    DocumentNumber:this.DocumentNumber,
                    AccountingUnit:this.accountingUnit,
                    Requisition:this.requisition,
                    Remarks:this.remarks,
                    Motive:this.selectedMotive,
                    FeeId:this.selectedFee,
                    SupplierId:this.selectedSupplier,
                    ClientId:""+this.selectedClient,
                    File:this.sigavFile,
                    Year:this.year,
                    NearestAirport:this.airport,
                    SalePrice:this.saleValue,
                    TransactionFee:this.fee,
                    DepartmentId:this.selectedDepartment
                });   
                
            alert("Sucesso");
            router.push({ path: '/' });
            return;

        }
    },
    computed:{
        departmentClass(){
            return this.selectedDepartment === null ? 'is-invalid' : '';
        },
        fileClass(){
            return this.sigavFile == null || this.sigavFile.length !== 7 ? 'is-invalid' : '';
        },
        supplierClass(){
            return this.selectedSupplier === null || this.selectedSupplier === '' ? 'is-invalid' : '';
        },
        saleClass(){
            return this.saleValue === null || this.saleValue === '' ? 'is-invalid' : '';
        },
        feeClass(){
            return this.fee === null || this.fee === '' ? 'is-invalid' : '';
        },
        airportClass(){
            return this.airport === null || this.airport.length !== 3 ? 'is-invalid' : '';
        },
        drpFeeClass(){
            return this.selectedFee === null || this.selectedFee === '' ? 'is-invalid' : '';
        },
        drpMotiveClass(){
            return this.selectedMotive === null || this.selectedMotive === '' ? 'is-invalid' : '';
        },
        clientClass(){
            return this.selectedClient === null || this.selectedClient === '' ? 'is-invalid' : '';
        }
    },
    async mounted() {
        this.bookingId = this.$route.query.id;
        
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;
        this.availableYears = Array.from({ length: nextYear - 2022 + 1 }, (_, index) => 2022 + index);
        this.year = currentYear;

        await api.get(`/sigav/refdata/`)
            .then(response => {
                response = response.data;
                this.motives = response.motives;
                this.fees = response.fees;
                this.departments = response.departments;
                this.suppliers = response.suppliers;
                this.clients = response.clients;
            });

        await api.get(`/hotel/booking/${this.bookingId}`)
            .then(response => {
                response = response.data;
                this.loading = false;
                this.firstName = response.firstName;
                this.lastName = response.lastName;
                this.phone = response.phone;
                this.email = response.email;
                this.date = response.dateCreated;
                this.total = response.total;
                this.saleValue = response.total;
                this.hotelName = response.hotelName;
                this.fromDate = response.fromDate;
                this.toDate = response.toDate;
                this.airport = response.closestAirport;
                this.supplierBookReference = response.supplierBookReference;
                //calculate nights using fromDate and toDate:
                const date1 = new Date(this.fromDate);
                const date2 = new Date(this.toDate);
                this.nights = Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
            });
    }
};
</script>
<style scoped>
.underline{
    text-decoration: underline;
}
</style>