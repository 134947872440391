<template>
    <div>
        <footer id="footer_area">
            <div class="container">
               
            </div>
        </footer>
        <div class="copyright_area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="copyright_left">
                            <p>Copyright © 2023 Alive Travel</p>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Footer'
}
</script>
