<template>
    <section id="theme_search_form">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="theme_search_form_area">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade  show active" id="hotels" role="tabpanel"
                                aria-labelledby="hotels-tab">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="tour_search_form">
                                            <form action="#!">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                                        <div class="">
                                                            <p>Destino</p>
                                                            <model-list-select :list="countries" option-value="id"
                                                                option-text="name" v-model="selectedCountry"
                                                                placeholder="Onde deseja ir?"
                                                                :customText="buildDestinationName"
                                                                :class="isLoading ? 'loading' : ''"
                                                                @searchchange="asyncFind">
                                                            </model-list-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div class="form_search_date">
                                                            <div >
                                                                <div class="Journey_date">
                                                                    <p>Check In - Check Out</p>
                                                                    <div>
                                                                        <Datepicker v-model="date" range format="dd-MM-yyyy"
                                                                        locale="pt-PT"
                                                                        select-text="Escolher"
                                                                        cancel-text="Cancelar"
                                                                        :min-date="new Date()"
                                                                        :enable-time-picker="false" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <CountDropdownHotel @count-updated="handleCountUpdated"/>
                                                    <div class="top_form_search_button">
                                                        <router-link v-if="selectedCountry != null && date != null"
                                                            :to="{ path: `hotel-search/${selectedCountry},${date[0].toLocaleDateString('pt-PT').replace(/[\/-]/g, ',')},${date[1].toLocaleDateString('pt-PT').replace(/[\/-]/g, ',')},${this.guests.numberAdults},${this.guests.numberChildren},${this.guests.numberRooms}` }"
                                                            class="btn btn_theme btn_md">Pesquisar</router-link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import { ModelListSelect } from "vue-search-select"
import CountDropdown from '@/components/home/CountDropdown.vue'
import CountDropdownRound from '@/components/home/CountDropdownRound.vue'
import CountDropdownMulti from '@/components/home/CountDropdownMulti.vue'
import CountDropdownTour from '@/components/home/CountDropdownTour.vue'
import CountDropdownHotel from '@/components/home/CountDropdownHotel.vue'
import "vue-search-select/dist/VueSearchSelect.css"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Cookies from 'js-cookie';

export default {
    name: "Form",

    components: {
        ModelListSelect, CountDropdown, CountDropdownRound, CountDropdownMulti, CountDropdownTour, CountDropdownHotel, Datepicker
    },
    mounted: function () {
        console.log(Cookies.get('token'));
        if (!Cookies.get('token')) {
            this.$router.push('/login');
        }

        this.isLogged = true;
    },
    data() {
        return {
            isLogged:true,
            isHidden: false,
            rowData: [{ name: '' }],
            selectedCountry: null,
            countries: [],
            date: null,
            isLoading: false,
            checkin: this.dateFormat(new Date()),
            checkout: this.dateFormat(new Date()),
            guests: {numberAdults:1,numberChildren:0,numberRooms:1}
        };
    },
    methods: {
        handleCountUpdated(newCount) {
           this.guests = newCount;
        },
        dateFormat(date) {
            const mm = date.getMonth() + 1;
            const dd = date.getDate();
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        },
        buildDestinationName({ name, parentName }) {
            if (parentName != null)
                return `${name}, ${parentName}`
            else
                return name;
        },

        searchHotels() {
            console.log(this.selectedCountries.get());
        },
        asyncFind(query) {


            if (query.length < 3) {
                return;
            }

            this.isLoading = true;

            fetch(`https://dev.alivetravel.com/api/destination/search2?search=${query}`, {
                method: 'GET'
            })
                .then(res => res.json())
                .then(response => {
                    this.countries = response
                    this.isLoading = false;
                })
        },
        clearAll() {
            this.selectedCountries = []
        }

    }
};
</script>


