<template>


  <section id="explore_area" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2 v-if="this.rawHotels.length">{{ this.hotelList.length }} resultados</h2>
            <h2 v-else>A carregar resultados...</h2>
          </div>
          <div class="section_heading_center">
            <h3>{{this.checkin}} a {{this.checkout}}</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="left_side_search_area" v-if="this.rawHotels.length">
            <div class="left_side_search_boxed">
              <div class="news_sidebar_content">
                <div class="news_sidebar_heading">
                  <h3>Filtrar / Ordenar</h3>
                </div>
                <div class="news_sidebar_search">
                  <form action="#!">
                    <div class="form-group">
                      <input type="text" v-model="hotelSearch" class="form-control"
                             placeholder="e.g Hotel castle salam">
                      <button type="submit" class="btn"><img src="../../assets/img/icon/search.png"
                                                             alt="icon"></button>
                    </div>

                  </form>
                  <h4>Ordernar por</h4>
                  <div class="form-group">
                    <select v-model="sortOrder" class="form-control form-select bg_input">
                      <option value="1">Preço (mais baixo)</option>
                      <option value="2">Preço (mais alto)</option>
                      <option value="3">Estrelas (mais estrelas)</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
            <div class="left_side_search_boxed">
              <div class="left_side_search_heading">
                <h5>Filtrar Preço</h5>
              </div>
              <div class="filter-price">
                <Slider v-model="value" class="apply" :min="minValue" :max="maxValue"
                        @update="priceChange(e)"/>
              </div>
            </div>
            <div class="left_side_search_boxed">
              <div class="left_side_search_heading">
                <h5>Filtros</h5>
              </div>
              <div class="tour_search_type">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="breakfastCheck" id="id1">
                  <label class="form-check-label" for="id1">
                                        <span class="area_flex_one">
                                            <span>Pequeno Almoço</span>
                                            <span>{{ numBreakfast }}</span>
                                        </span>

                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="noStarCheck" id="id2">
                  <label class="form-check-label" for="id2">
                                        <span class="area_flex_one">
                                            <span>Sem Classificação</span>
                                            <span>{{ noStars }}</span>
                                        </span>

                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="oneStarCheck" id="id3">
                  <label class="form-check-label" for="id3">
                                        <span class="area_flex_one">
                                            <span>1 Star</span>
                                            <span>{{ oneStar }}</span>
                                        </span>

                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="twoStarCheck" id="id4">
                  <label class="form-check-label" for="id4">
                                        <span class="area_flex_one">
                                            <span>2 Star</span>
                                            <span>{{ twoStar }}</span>
                                        </span>

                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="threeStarCheck" id="id5">
                  <label class="form-check-label" for="id5">
                                        <span class="area_flex_one">
                                            <span>3 Star</span>
                                            <span>{{ threeStar }}</span>
                                        </span>

                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="fourStarCheck" id="id6">
                  <label class="form-check-label" for="id6">
                                        <span class="area_flex_one">
                                            <span>4 Star</span>
                                            <span>{{ fourStar }}</span>
                                        </span>

                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="fiveStarCheck" id="id7">
                  <label class="form-check-label" for="id7">
                                        <span class="area_flex_one">
                                            <span>5 Star</span>
                                            <span>{{ fiveStar }}</span>
                                        </span>

                  </label>
                </div>

              </div>
            </div>
            <div class="left_side_search_boxed" v-if="false">
              <div class="left_side_search_heading">
                <h5>Serviços</h5>
              </div>
              <!-- <div class="tour_search_type">
                   <div class="form-check">
                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefaultt1">
                       <label class="form-check-label" for="flexCheckDefaultt1">
                           <span class="area_flex_one">
                               <span>Ginásio</span>
                               <span>20</span>
                           </span>
                       </label>
                   </div>
               </div>
               -->
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div v-for='(hotelInfo, index) in hotelList' class="">
              <div class="theme_common_box_two" style="display: flex">
                <div class="theme_two_box_img" style="width: 35%;height: 250px">
                  <img
                      class="img-hotel-thumb"
                      v-if="hotelInfo.images != null && hotelInfo.images.length > 0"
                      :src="`http://photos.hotelbeds.com/giata/${hotelInfo.images[0].path}`"
                      :alt="hotelInfo.name"/>
                  <p><i class="fas fa-map-marker-alt"></i>{{ hotelInfo.locationText }}</p>
                </div>
                <div class="theme_two_box_content">
                  <h4>
                    <router-link
                        :to="{ path: `hotel-detail`,params:{hotel:hotelInfo},query:{id:hotelInfo.id,from:checkin,to:checkout,adults:adults,children:children,rooms:rooms} }">
                      {{ hotelInfo.name }}
                      <div>{{ stars(hotelInfo.categoryCode) }}</div>
                    </router-link>
                  </h4>
                  <h3>{{ hotelInfo.minRoom.name }}</h3>
                  <h3>Net: {{ hotelInfo.priceFrom }}<span>€</span></h3>
                  <h3>Pvp: {{ hotelInfo.minRoom.rates[0].sellingRate }}<span>€</span></h3>
                  <h5>Disponibilidade: {{ hotelInfo.minRoom.rates[0].allotment }} </h5>
                  <h6 v-if="hotelInfo.minRoom.rates[0].breakfast">Com pequeno almoço</h6>
                  <h6 v-if="hotelInfo.minRoom.rates[0].noRefund">Não Reembolsável</h6>
                  <div>
                    <router-link
                        :to="{ path: `hotel-detail`,params:{hotel:hotelInfo},query:{id:hotelInfo.id,from:checkin,to:checkout,adults:adults,children:children,rooms:rooms} }">
                      <button  class="btn btn_theme btn_md w-100" type="button">Reservar</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="pagination_area" v-if="false">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {ModelListSelect} from "vue-search-select"
import Slider from '@vueform/slider'
import data from '../../data'
import CountDropdownHotel from '@/components/home/CountDropdownHotel.vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import api from '@/api.js';

export default {
  name: "SearchResult",
  components: {
    Slider, CountDropdownHotel, Datepicker, ModelListSelect
  },
  computed: {
    hotelList() {
      var finalList = [];
      if (this.fiveStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode === "5EST"));
      }
      if (this.fourStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode === "4EST"));
      }
      if (this.threeStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode === "3EST"));
      }
      if (this.twoStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode === "2EST"));
      }
      if (this.oneStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode === "1EST"));
      }
      if (this.noStarCheck) {
        finalList = finalList.concat(this.rawHotels.filter(item => item.categoryCode !== "1EST" && item.categoryCode !== "2EST" && item.categoryCode !== "3EST" && item.categoryCode !== "4EST" && item.categoryCode !== "5EST"));
      }

      finalList = finalList.filter(item => item.rooms.some(a => a.rates.some(b => b.breakfast)));


      if (this.hotelSearch != null && this.hotelSearch != "") {
        finalList = finalList.filter(item => item.name.toLowerCase().includes(this.hotelSearch.toLowerCase()));
      }

      finalList = finalList.filter(item => item.priceFrom >= this.value[0] && item.priceFrom <= this.value[1])

      if (this.sortOrder == 1)
        return finalList.sort((a, b) => a.priceFrom - b.priceFrom);
      else if (this.sortOrder == 2)
        return finalList.sort((a, b) => b.priceFrom - a.priceFrom);
      else if (this.sortOrder == 3)
        return finalList.sort((a, b) => this.stars(a.categoryCode) - this.stars(b.categoryCode));
    }
  },
  methods: {
    stars(s) {
      if (s.includes('EST')) {
        const starCount = parseInt(s.replace('EST', ''));
        return '⭐'.repeat(starCount);
      } else {
        return "";
      }
    },
    priceChange() {
      this.hotels = this.rawHotels.filter(item => item.priceFrom >= this.value[0] && item.priceFrom <= this.value[1]);
    }, dateFormat(date) {
      const mm = date.getMonth() + 1;
      const dd = date.getDate();
      const yyyy = date.getFullYear();
      return `${mm}/${dd}/${yyyy}`;
    }
  },
  data() {
    return {
      value: [0, 1000],
      minValue: 1,
      maxValue: 2000,
      items: [],
      rowData: [{name: ''}],
      selectedCountry: null,
      hotels: [],
      countries: [],
      rawHotels: [],
      date: null,
      checkin: this.dateFormat(new Date()),
      checkout: this.dateFormat(new Date()),
      adults: 1,
      children: 0,
      rooms: 1,
      numBreakfast: 0,
      noStarCheck: true,
      oneStarCheck: true,
      twoStarCheck: true,
      threeStarCheck: true,
      fourStarCheck: true,
      fiveStarCheck: true,
      breakfastCheck: true,
      hotelSearch: '',
      sortOrder: 1
    }
  },
  async mounted() {
    this.items = data.hotelData;
    let codes = this.$route.query.code.split(',');

    this.code = `${codes[0]},${codes[1]}`;
    this.checkin = `${codes[4]}-${codes[3]}-${codes[2]}`;
    this.checkout = `${codes[7]}-${codes[6]}-${codes[5]}`;
    this.date = [new Date(codes[4], codes[3] - 1, codes[2]), new Date(codes[7], codes[6] - 1, codes[5])];
    this.adults = codes[8];
    this.children = codes[9];
    this.rooms = codes[10];

    api.get(`/hotel/search?start=${this.checkin}&end=${this.checkout}&zoneCode=${this.code}&rooms=${this.rooms}&adults=${this.adults}&children=${this.children}`)
        .then(response => {
          response = response.data;
          this.hotels = response;
          this.rawHotels = response;
          const prices = response.map(item => item.priceFrom);
          this.minValue = Math.min(...prices);
          this.maxValue = Math.max(...prices);
          this.value = [this.minValue, this.maxValue];
          this.numBreakfast = this.hotels.filter(item => item.rooms.some(a => a.rates.some(b => b.breakfast))).length;
          this.oneStar = this.hotels.filter(item => item.categoryCode === "1EST").length;
          this.twoStar = this.hotels.filter(item => item.categoryCode === "2EST").length;
          this.threeStar = this.hotels.filter(item => item.categoryCode === "3EST").length;
          this.fourStar = this.hotels.filter(item => item.categoryCode === "4EST").length;
          this.fiveStar = this.hotels.filter(item => item.categoryCode === "5EST").length;
          this.noStars = this.hotels.filter(item => item.categoryCode !== "1EST" && item.categoryCode !== "2EST" && item.categoryCode !== "3EST" && item.categoryCode !== "4EST" && item.categoryCode !== "5EST").length;

        });

  },
  async searchHotels() {


  }


};
</script>
<style src="@vueform/slider/themes/default.css">

</style>

<style scoped>
.img-hotel-thumb {
  height: 250px;
  object-fit: cover;
}
</style>
