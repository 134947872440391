<template>
    <FullScreenLoader v-if="isLoading" />
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="tou_booking_form_Wrapper">
                        <div class="booking_tour_form">
                            <h3 class="heading_theme">Confirmação de Reserva</h3>
                            <div class="tour_booking_form_box">
                                <form action="!#" id="tour_bookking_form_item">
                                    <div   v-for="(adult, index) in adultsData" :key="index"  class="row">
                                        <div>Hóspede {{ index+1 }}</div>
                                      <div class="col-lg-6">
                                        <div class="form-group">
                                          <input
                                              type="text"
                                              class="form-control bg_input"
                                              v-model="adult.firstName"
                                              :class="firstNameClass(index)"
                                              :placeholder="`Primeiro Nome`"
                                              required
                                          >
                                        </div>   </div>
                                        <div class="col-lg-6">
                                          <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control bg_input"
                                                v-model="adult.lastName"
                                                :class="lastNameClass(index)"
                                                :placeholder="`Último Nome`"
                                                required
                                            >  </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="email"
                                                    placeholder="Email (Opcional)">
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control bg_input" v-model="phone"
                                                    placeholder="Telemóvel">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="booking_tour_form_submit">
                            <a @click="confirmBooking()" class="btn btn_theme btn_md">Confirmar Reserva</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tour_details_right_sidebar_wrapper">
                        <div class="tour_detail_right_sidebar" v-if="this.hotel">
                            <div class="tour_details_right_boxed">
                                <div class="tour_details_right_box_heading">
                                    <h3>Detalhe</h3>
                                </div>
                                <div class="tour_booking_amount_area">
                                    <ul>
                                        <li>{{ this.hotel.name }}</li>
                                        <li>{{ this.room.name }}</li>
                                        <li v-if="this.rate.breakfast">Pequeno Almoço</li>
                                        <li>{{ this.from }} a {{ this.to }}</li>
                                        <li>{{ this.adults }} Adultos {{ this.rooms }} Quarto <span>{{ this.rate.net
                                        }}€</span></li>
                                    </ul>
                                    <div class="tour_bokking_subtotal_area">
                                        <h6>Total <span>{{ this.rate.net }}€</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>
    <div v-if="errorMessage != null" class="confirmation-popup">
      <div class="popup-content">
        <h3>Erro</h3>
        <p>{{ errorMessage }}</p>

        <div class="buttons">
          <button @click="cancelError" class="cancel-button">Ok</button>
        </div>
      </div>
    </div>


</template>
<script>
import router from '@/router';
import api from '@/api.js';
import FullScreenLoader from '@/components/FullScreenLoader'

export default {
    components: {
    FullScreenLoader
  },
    name: "TourBookingSubmission",
    data() {
        return {
            hotel: null,
            room: null,
            rate: null,
            from: null,
            adultsData:[],
            to: null,
            adults: 0,
            children: null,
            rooms: null,
            searchId: null,
            rateId: null,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            isLoading: true,
            errorMessage:  null
        }
    },
    computed: {
      firstNameClass() {
        return (index) => {
          return {
            'is-invalid': this.adultsData[index].firstName.length < 3
          }
        }
      },
      lastNameClass() {
        return (index) => {
          return {
            'is-invalid': this.adultsData[index].lastName.length < 3
          }
        }
      }
        },
    methods: {
        cancelError(){
            this.errorMessage = null;
        },
        confirmBooking() {


            const data = {
                searchId: this.searchId,
                rateId: this.rateId,
                email: this.email,
                phone: this.phone,
                firstName: this.adultsData[0].firstName,
                lastName: this.adultsData[0].lastName,
                guests: this.adultsData.map(adult => ({
                    firstName: adult.firstName,
                    lastName: adult.lastName
                }))
            }
            this.isLoading = true;
            api.post(`/hotel/book`, data)
            .then(response => {
                this.isLoading = false;
                console.log(response);
                if(response.data.error) {
                    this.errorMessage = response.data.error.message;
                    return;
                }
                router.push({ path: '/booking-confirmation', query: {id:response.data.id} });
            }).catch(error => {
                this.isLoading = false;
                console.log(error);
            });
        }
    },
    async mounted() {
        this.searchId = this.$route.query.id;
        this.rateId = this.$route.query.rate;
        this.isLoading = true;

        await api.get(`/hotel/getSearch/${this.searchId}`)
            .then(response => {
                response = response.data;
                this.hotel = response.hotels[0];
                this.room = this.hotel.rooms.filter(room => room.rates.some(rate => rate.rateKey == this.rateId))[0];
                this.rate = this.room.rates.filter(rate => rate.rateKey ==  this.rateId)[0];
                this.from = response.from;
                this.to = response.to;
                this.adults = response.adults;
                this.children = response.children;
                this.rooms = response.rooms;
                this.email = response.email;
                this.isLoading = false;
                this.adultsData  = Array.from({ length: this.adults }, (_, index) => ({
                  firstName: '',
                  lastName: ''
                }));
            });
    }
}

</script>

<style scoped>
 .confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #ccc;
  color: black;
  border: none;
}
</style>
