<template>
  <Banner />
  <Form />
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/home/Banner.vue'
import Form from '@/components/home/Form.vue'
import Destinations from '@/components/home/Destinations.vue'
import News from '@/components/home/News.vue'
import Partners from '@/components/home/Partners.vue'
import Cta from '@/components/home/Cta.vue'



export default {
  name: 'HomeView',
  components: {
    Banner, Form,  Destinations, News, Partners, Cta
  }
}
</script>